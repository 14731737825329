<template>
    <van-popup v-model="showBag" @close="closePopup" position="bottom" style="background-color: transparent">
        <div class="popup-box">
            <div class="top-title">
                <div class="title row-between-center">
                    <span>选择红包封面和赠言</span>
                    <van-icon @click="closePopup" size="4.8vw" color="#6C727A" name="cross" />
                </div>
            </div>
            <div class="popup-card-list">
                <div class="bag-bg" :style="`background-image: url(${resultItem.backgroundImgUrl})`">
                    <div class="user row-center-center">
                        <img :src="userInfo.headImg" alt="">
                        <p>{{ userInfo.nickName }}的红包</p>
                    </div>
                    <div class="bag-text row-center-center">
                        <p>{{ bagText }}</p>
                        <img @click="showInput" src="https://img.chaolu.com.cn/ACT/give-equity-card/edit1.png" alt="">
                    </div>
                    <div class="bag-bg-open"></div>
                </div>
            </div>
            <div class="popup-btn">
                <div>
                    <h4>选择红包封面</h4>
                    <div class="row-start-start">
                        <div @click="resultItem = item" v-for="item in list" :key="item.id" :class="{active: item.id === resultItem.id}">
                            <img :src="item.backgroundImgUrl" alt="">
                            <img v-if="item.id === resultItem.id" class="selected" src="https://img.chaolu.com.cn/ACT/give-equity-card/selected.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="row-between-center">
                    <div @click="selectConfirm" class="row-center-center">选好了</div>
                </div>
            </div>
        </div>
        <van-popup v-model="showEdit" style="background-color: transparent">
            <div class="edit-box">
                <div class="edit-title">请输入</div>
                <div class="edit-text">
                    <van-field maxlength="10" show-word-limit v-model="editText" placeholder="请输入赠言，最多10个字" />
                </div>
                <div class="edit-btn row-between-center">
                    <div @click="showEdit = false">取消</div>
                    <div @click="confirm">提交</div>
                </div>
            </div>
        </van-popup>
    </van-popup>
</template>

<script>
import userMixin from '@/mixin/userMixin';
export default {
    name: "select-bg",
    mixins: [userMixin],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        resultBgItem: {
            type: Object,
            default() {
                return {}
            }
        },
        text: {
            type: String,
            default: ''
        }
        
    },
    data() {
        return {
            showBag: false,
            showEdit: false,
            resultItem: {},
            bagText: '让我请你去超鹿运动吧',
            editText: '',
            id: null,
            list: [],
            userInfo: {},
        }
    },
    async created() {
        await this.$getUserId();
        this.getUserInfo()
        this.getBgList()
    },
    methods: {
        getUserInfo() {
            this.$axios.post(`${this.baseURLApp}/user/personal/info`, {
                token: this.token,
                userId: this.userId,
            }).then(res => {
                this.userInfo = res.data || {}
            })
        },
        getBgList() {
            this.$axios.post(`${this.baseURLApp}/common/redPacketConf/list`, {
                token: this.token,
                userId: this.userId
            }).then(res => {
                this.list = res.data || []
                this.resultItem = this.list[0] || {}
                this.$emit('confirm', this.resultItem, this.bagText)
            })
        },
        clearAll() {
            this.list.forEach(item => {
                item.active = false
            })
        },
        selectConfirm() {
            this.$emit('update:show', false)
            this.$emit('confirm', this.resultItem, this.bagText)
        },
        closePopup() {
            this.list.map(item => {
                item.active = false
            })
            this.showBag = false
            this.$emit('update:show', false)
        },
        showInput() {
            this.showEdit = true
            this.editText = this.bagText
        },
        confirm() {
            if(!this.editText){
                this.$toast('请输入赠言，最多10个字!')
                return false
            }
            this.showEdit = false
            this.bagText = this.editText
        }
    },
    watch: {
        show(val) { // 显示隐藏
            this.showBag = val
            if(val){
                this.resultItem = this.resultBgItem
                this.bagText = this.text
            }
        }
    },
}
</script>

<style scoped lang="less">
.popup-box{
    width: 100vw;
    height: 95vh;
    background-color: #F5F5F5;
    border-radius: 16px 16px 0 0;
    position: relative;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 512px;
    box-sizing: border-box;
    .popup-card-list{
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 32px 24px;
        background: url("https://img.chaolu.com.cn/ACT/give-equity-card/page_bg.png") no-repeat center center;
        background-size: cover;
        .bag-bg{
            width: 620px;
            height: 852px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin: 0 auto;
            position: relative;
            .user{
                padding-top: 84px;
                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 16px;
                }
                p{
                    font-size: 24px;
                    line-height: 24px;
                    color: #FFDEA5;
                }
            }
            .bag-text{
                margin-top: 24px;
                img{
                    width: 88px;
                    height: 36px;
                    margin-left: 12px;
                }
                p{
                    font-size: 32px;
                    line-height: 32px;
                    color: #FFDEA5;
                }
            }
            .bag-bg-open{
                width: 176px;
                height: 176px;
                border-radius: 50%;
                /*background-color: #242831;*/
                background: url("https://img.chaolu.com.cn/ACT/give-equity-card/reg_bag_btn.png") no-repeat center center;
                background-size: 100% 100%;
                position: absolute;
                left: 222px;
                top: 572px;
            }
        }
    }
    .top-title{
        background: #FFDEC0;
        flex-shrink: 0;
        .title{
            font-weight: bold;
            line-height: 32px;
            color: #242831;
            font-size: 32px;
            padding: 48px 32px 32px;
        }
        .top-nav{
            padding: 24px 32px;
            display: flex;
            div{
                padding: 14px 24px;
                background: #F5F5F5;
                font-size: 22px;
                color: #3C454E;
                line-height: 24px;
                border-radius: 4px;
                border: 2px solid #F5F5F5;
                & + div{
                    margin-left: 16px;
                }
                &.active{
                    border-color: #242831;
                    font-weight: bold;
                    color: #242831;
                }
            }
        }
    }
    .popup-btn{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 750px;
        height: 512px;
        background: #FFFFFF;
        &>div:first-child{
            padding: 32px 32px 0;
            h4{
                margin-bottom: 16px;
                font-size: 28px;
                line-height: 28px;
                color: #242831;
            }
            &>div{
                overflow-x: auto;
                padding: 24px 0;
                &::-webkit-scrollbar{
                    display: none;
                }
                div{
                    width: 172px;
                    height: 236px;
                    background: #FF4E2D;
                    border-radius: 12px;
                    box-sizing: border-box;
                    margin-right: 24px;
                    flex-shrink: 0;
                    position: relative;
                    border: 6px solid #ffffff;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        border: 6px solid #FFDE00;
                    }
                    .selected{
                        width: 44px;
                        height: 44px;
                        position: absolute;
                        right: -22px;
                        top: -22px;

                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        &>div:last-child{
            width: 750px;
            height: 152px;
            background: #FFFFFF;
            padding: 28px 32px;
            box-sizing: border-box;
            div{
                width: 686px;
                height: 96px;
                background: #FFDE00;
                border-radius: 8px;
                font-weight: bold;
                color: #242831;
                font-size: 28px;
            }
        }
    }
}
.edit-box{
    width: 686px;
    height: 444px;
    background: #FFFFFF;
    border-radius: 16px;
    .edit-title{
        text-align: center;
        padding: 40px 0;
        font-weight: bold;
        color: #242831;
        line-height: 38px;
        font-size: 32px;
    }
    .edit-text{
        width: 622px;
        margin: 0 auto 32px;
        //height: 136px;
        //background: #FFFFFF;
        overflow: hidden;
        border-radius: 16px ;
        border: 1px solid #EEEEEE;
    }
    .edit-btn{
        width: 686px;
        height: 152px;
        padding: 0 32px;
        box-sizing: border-box;
        div{
            width: 296px;
            height: 96px;
            border-radius: 8px;
            opacity: 1;
            border: 1px solid #6C727A;
            font-size: 28px;
            color: #242831;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            &:last-child{
                font-weight: bold;
                background: #FFDE00;
                border-color: #FFDE00;
            }
        }
    }
}
</style>
