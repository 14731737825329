<template>
    <van-popup v-model="showCard" @close="closePopup" position="bottom" style="background-color: transparent">
        <div class="popup-box">
            <div class="top-title">
                <div class="title row-between-center">
                    <span>选择要赠送的卡券</span>
                    <van-icon @click="closePopup" size="4.8vw" color="#6C727A" name="cross" />
                </div>
                <div class="top-nav">
                    <div :class="{active: cardList.length}">权益卡</div>
<!--                    <div>权益卡</div>-->
                </div>
            </div>
            <div v-if="cardList.length" class="popup-card-list">
                <div class="popup-card-item" v-for="(item, index) in cardList" :key="index">
                    <div class="popup-card-item-t row-between-center">
                        <img :src="item.iconUrl" alt="">
                        <div @click="jumpDetail(item.equityCardGiftId)">
                            <h4 class="row-between-center">
                                <span>{{ item.equityCardName }}</span>
                                <van-icon color="#9AA1A9" name="arrow" />
                            </h4>
                            <p v-if="item.useValidity">生效天数：{{ item.useValidity }}</p>
                            <p v-if="item.buyTime">购买时间：{{ item.buyTime }} </p>
                            <p v-if="item.givingDeadlineDate">说明：{{ item.givingDeadlineDate }}前未赠送将原路退卡退款</p>
                        </div>
                    </div>
                    <div class="popup-card-item-b row-between-center">
                        <div><p v-if="item.cardStatusEnum === 'WAIT_RECEIVE'">赠送中</p></div>
                        <p v-if="['WAIT_OPEN', 'WAIT_ACTIVE'].includes(item.cardStatusEnum)" @click="selected(item)" :class="{active: item.active}"></p>
                    </div>
                </div>
            </div>
            <div v-else class="popup-card-list empty col-center-center">
                <img src="https://img.chaolu.com.cn/ACT/give-equity-card/empty.png" alt="">
                <p>您还没有可赠送的卡券</p>
                <div @click="jumpHome()" class="row-center-center">去买一张<van-icon size="3.2vw" style="font-weight: bold;margin-left: 1.07vw" name="arrow" /></div>
            </div>
            <div class="popup-btn">
                <div class="row-start-center">已选<h4>{{ resultList.length }}</h4>张卡券</div>
                <div class="row-between-center">
                    <p @click="clearAll" class="row-center-center">清空所选</p>
                    <div :style="resultList.length? '': 'filter: grayscale(100%)'" @click="selectConfirm" class="row-center-center">选好了</div>
                </div>
            </div>
        </div>
    </van-popup>
</template>

<script>
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
export default {
    name: "select-card",
    mixins: [userMixin],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        selectedList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            showCard: false,
            cardList: [],
            resultList: [],
        }
    },
    async created() {
        await this.$getUserId();
    },
    computed: {
        num() {
            let arr = []
            this.cardList.forEach(item => {
                if(item.active){
                    arr.push(item)
                }
            })
            return arr.length
        }
    },
    methods: {
        getDataList() {
            this.$axios.post(`${this.baseURLApp}/user/giving/cardVoucher/list`, {
                token: this.token,
                userId: this.userId
            }).then(res => {
                this.cardList = res.data.equityCardGiftList || []
                this.cardList.forEach(v => {
                    v.active = this.selectedList.some(row => {return row.equityCardGiftId === v.equityCardGiftId})
                })
            })
        },
        selected(item) {
            this.$set(item, 'active', !item.active)
            this.resultList = []
            this.cardList.forEach(item => {
                if(item.active){
                    this.resultList.push(item)
                }
            })
            this.$forceUpdate()
        },
        clearAll() {
            this.cardList.forEach(item => {
                item.active = false
            })
            this.resultList = []
            this.$forceUpdate()
        },
        selectConfirm() {
            if(!this.resultList.length){
                this.$toast('请选择要赠送卡券！')
                return false
            }
            this.resultList = []
            this.cardList.forEach(item => {
                if(item.active){
                    this.resultList.push(item)
                }
            })
            this.$emit('update:show', false)
            this.$emit('confirm', this.resultList)
        },
        closePopup() {
            this.cardList.map(item => {
                item.active = false
            })
            this.showCard = false
            this.$emit('update:show', false)
        },
        jumpDetail(equityCardGiftId) {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/equity-detail&userId=1&token=1&equityCardGiftId=${equityCardGiftId}`,
                })
            } else {
                this.$router.push(`/month-card/equity-detail?equityCardGiftId=${equityCardGiftId}`)
            }
        },
        jumpHome(){
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership&cityId=1`,
                })
            } else {
                this.$router.push('/superdeer-activity/sell-membership?cityId=1')
            }
        }
    },
    watch: {
        show(val) { // 显示隐藏
            this.showCard = val
            if(val){
                this.getDataList()
                this.resultList = this.selectedList
            }
        }
    },
}
</script>

<style scoped lang="less">
.popup-box{
    width: 100vw;
    height: 95vh;
    background-color: #F5F5F5;
    border-radius: 16px 16px 0 0;
    position: relative;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    padding-bottom: calc(232px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    .popup-card-list{
        flex-grow: 1;
        overflow-y: auto;
        padding: 0 32px 24px;
        .popup-card-item{
            border-radius: 16px;
            overflow: hidden;
            margin-top: 24px;
            background: url("https://img.chaolu.com.cn/ACT/give-equity-card/card_list_bg.png") no-repeat center center;
            background-size: 100% 100%;
            width: 686px;
            height: 328px;
            .popup-card-item-t{
                &>div{
                    height: 224px;
                    flex-grow: 1;
                    padding: 0 20px;
                    h4{
                        font-size: 28px;
                        line-height: 28px;
                        color: #242831;
                        padding-top: 32px;
                        margin-bottom: 40px;
                    }
                    p{
                        margin-top: 16px;
                        font-size: 20px;
                        line-height: 20px;
                        color: #3C454E;
                    }
                }
                &>img{
                    flex-shrink: 0;
                    width: 232px;
                    height: 224px;
                }
            }
            .popup-card-item-b{
                height: 104px;
                padding: 0 32px;
                div{
                    p{
                        font-size: 24px;
                        color: #6C727A;
                        line-height: 24px;
                        padding: 8px 12px;
                        background: #EEEEEE;
                    }
                }
                &>p{
                    width: 40px;
                    height: 40px;
                    background: url("https://img.chaolu.com.cn/ACT/give-equity-card/no_select.png") no-repeat center center;
                    background-size: 100% 100%;
                    &.active{
                        background-image: url("https://img.chaolu.com.cn/ACT/give-equity-card/selected1.png");
                    }
                }
            }
        }
        &.empty{
            img{
                width: 320px;
                height: 320px;
            }
            p{
                font-size: 24px;
                color: #6C727A;
                line-height: 24px;
                margin-top: 16px;
                text-align: center;
            }
          div{
            font-size: 24px;
            font-weight: bold;
            color: #242831;
            margin-top: 32px;
          }
        }
    }
    .top-title{
        background: #FFFFFF;
        flex-shrink: 0;
        .title{
            font-weight: bold;
            line-height: 32px;
            color: #242831;
            font-size: 32px;
            padding: 48px 32px 32px;
        }
        .top-nav{
            padding: 24px 32px;
            display: flex;
            div{
                padding: 14px 24px;
                background: #F5F5F5;
                font-size: 22px;
                color: #3C454E;
                line-height: 24px;
                border-radius: 4px;
                border: 2px solid #F5F5F5;
                & + div{
                    margin-left: 16px;
                }
                &.active{
                    border-color: #242831;
                    font-weight: bold;
                    color: #242831;
                }
            }
        }
    }
    .popup-btn{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 750px;
        height: 232px;
        background: #FFFFFF;
        padding-bottom: env(safe-area-inset-bottom);
        &>div:first-child{
            padding: 32px 32px 0;
            font-weight: bold;
            line-height: 50px;
            font-size: 28px;
            h4{
                padding: 8px 14px;
                background: #FFDE00;
                border-radius: 8px 8px 8px 8px;
                font-size: 32px;
                line-height: 32px;
                color: #242831;
                margin: 0 8px;
            }
        }
        &>div:last-child{
            width: 750px;
            height: 152px;
            background: #FFFFFF;
            padding: 28px 32px;
            box-sizing: border-box;
            p{
                width: 224px;
                height: 96px;
                border-radius: 8px;
                border: 1px solid #6C727A;
                box-sizing: border-box;
                color: #242831;
                font-size: 28px;
            }
            div{
                width: 432px;
                height: 96px;
                background: #FFDE00;
                border-radius: 8px;
                font-weight: bold;
                color: #242831;
                font-size: 28px;
            }
        }
    }
}
</style>
